import { format, isValid } from "date-fns";
import { get } from "lodash";
import React, { Fragment } from "react";
import styled from "styled-components";
import { A, P, Small } from "@upsolve/ui";
import ImageInContext from "../Media/ImageInContext";
import * as DTI from "../../../utils/dataTestIds";

const AuthorsBlock = ({ authors = [], createdAt, reviewedAt, updatedAt, verb = "Written" }) => {
  const authorsFinal =
    authors.length > 0
      ? authors.map((author) => ({
          name: author.name,
          slug: author.slug,
          photoUrl: get(author, "image.image.file.url"),
          fluid: get(author, "image.image.fluid"),
        }))
      : [
          {
            name: "Upsolve Team",
            slug: "upsolve-team",
            photoUrl: "https://static.upsolve.org/logos/upsolve-icon-512.png",
          },
        ];

  return (
    <AuthorBlockWrapper dataTestId={DTI.AUTHORS_BLOCK}>
      <div className="content-author__images">
        {authorsFinal
          .filter((author) => author.photoUrl != null)
          .map((author, authorIndex) => (
            <ImageInContext
              key={authorIndex}
              className="author-image"
              alt={get(author, "name", "")}
              src={author.photoUrl}
              fluid={author.fluid}
            />
          ))}
      </div>
      <P>
        <b>
          {verb} by{" "}
          {authorsFinal
            .filter(
              (author, authorsIndex, authorsArray) => authorsIndex === 0 || authorsIndex < authorsArray.length - 1
            )
            .map((author, authorIndex, authorsArray) => (
              <Fragment key={authorIndex}>
                <A aria-busy="false" href={`/authors/${author.slug}/`}>
                  {author.name}
                </A>
                {authorsArray.length > 1 && authorIndex !== authorsArray.length - 1 ? ", " : ""}
              </Fragment>
            ))}
          .&nbsp;
          {authorsFinal.length > 1 && (
            <Fragment>
              Legally reviewed by{" "}
              {authors.slice(-1).map((author, authorIndex) => (
                <A key={authorIndex} href={`/authors/${author.slug}/`}>
                  {author.name}
                </A>
              ))}
            </Fragment>
          )}
        </b>
        <br />
        {(reviewedAt || updatedAt || createdAt) && isValid(new Date(reviewedAt || updatedAt || createdAt)) && (
          <Small>
            {(() => {
              if (reviewedAt) return "Reviewed";
              if (updatedAt) return "Updated";
              return "Written";
            })()}{" "}
            {format(new Date(reviewedAt || updatedAt || createdAt), "MMMM d, yyyy")}
          </Small>
        )}
      </P>
    </AuthorBlockWrapper>
  );
};

export const AuthorBlockWrapper = styled.div.attrs((props) => ({
  "data-test-id": props.dataTestId,
}))`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.white["900"]};
  padding: 0.1em 0.5em;
  margin: -0.1em -0.5em;
  & > div {
    margin: 1.25em 0;
  }
  .author-image,
  img {
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    border-radius: 36px;
  }
  & > p {
    margin: 1em 0.75em;
    font-size: 14px;
  }
  small {
    color: ${(props) => props.theme.colors.gray[500]};
  }
  &__images {
    display: flex;
    padding-right: 4px;
    & > * {
      margin: 0 -4px;
    }
  }
`;

export default AuthorsBlock;
