import { get } from "lodash";
import React from "react";
import { H3 } from "@upsolve/ui";
import AuthorsBlock from "./AuthorsBlock";
import ArticleTile from "../Learn/ArticleTile.div";
import richTextNodeToComponent from "../Contentful/richTextNodeToComponent";

const PageTile = ({ page }) => {
  const authors = (page.authors || [page.author]).filter((author) => author && author.name != null);
  return (
    <ArticleTile key={page.path}>
      <div>
        <H3>
          <a href={page.path}>{page.title}</a>
        </H3>
        <AuthorsBlock authors={authors} createdAt={page.createdAt} updatedAt={page.updatedAt} />
        {get(page?.summary, "json") != null && (
          <div className="content-body__lead">
            <div className="summary">
              {Array.prototype.map.call(page?.summary.json.content, (c, i, a) =>
                richTextNodeToComponent(c, i, a, { locality: page?.locality })
              )}
            </div>
          </div>
        )}
        <a href={page.path}>Read More →</a>
      </div>
    </ArticleTile>
  );
};

export default PageTile;
