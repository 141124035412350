import styled from "styled-components";
import { theme } from "@upsolve/ui";

export const Label = styled.label`
  background: ${theme.colors.brand["900"]};
  padding: 0.4em 1.25em;
  border-radius: 1em;
  color: ${theme.colors.brand["500"]};
  font-size: 0.7em;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-transform: uppercase;
`;

export default Label;
