import { get, kebabCase } from "lodash";
import upsolveJSONLD from "./upsolveJSONLD";

export default function authorToJSONLD(author) {
  return {
    "@context": "http://schema.org",
    "@type": "Person",
    description: get(author, "biography.biography") || "",
    email: author.email || "contributors@upsolve.org",
    image: {
      "@type": "ImageObject",
      url:
        get(author, "image.image.file.url") != null
          ? `https:${get(author, "image.image.file.url")}`
          : "https://upsolve.org/images/blob-attorneyHeadShot.png",
    },
    jobTitle: author.role,
    knowsAbout: "Bankruptcy, Chapter 7, Debt",
    name: author.name,
    sameAs: (get(author, "profiles") || []).map((p) => p.url),
    url: `https://upsolve.org/authors/${kebabCase(author.name).toLowerCase()}/`,
    worksFor: upsolveJSONLD,
  };
}
