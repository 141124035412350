import { get, kebabCase } from "lodash";
import Link from "gatsby-link";
import React from "react";
import { H3, P, Small } from "@upsolve/ui";
import AuthorsBlock from "../Layout/AuthorsBlock";
import ArticleTile from "./ArticleTile.div";
import { Label } from "../Type/Label.label";

const LearnArticleTile = ({ article }) => {
  const authors = (article.authors || [article.author]).filter((author) => author && author.name != null);
  return (
    <ArticleTile key={article.slug}>
      <div>
        <H3>
          <a href={`/learn/${article.slug}/`}>{article.title}</a>
        </H3>
        <AuthorsBlock authors={authors} createdAt={article.createdAt} updatedAt={article.updatedAt} />
        {get(article, "articleExcerpt.articleExcerpt") != null && (
          <P>{get(article, "articleExcerpt.articleExcerpt")}</P>
        )}
        <a href={`/learn/${article.slug}/`}>Read More →</a>
      </div>
    </ArticleTile>
  );
};

export default LearnArticleTile;
