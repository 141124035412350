import styled from "styled-components";
import { ACSS, theme } from "@upsolve/ui";
import { AuthorBlockWrapper } from "../Layout/AuthorsBlock";

const ArticleTile = styled.div`
  margin: 1em 4em 2em;
  padding: 2em 4em;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px ${theme.colors.white["500"]}, 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  a {
    ${ACSS}
  }
  // Hide labels, they clutter the interface but are nice internal links
  label {
    display: none;
  }
  ${AuthorBlockWrapper} {
    margin: -0.5em -0.5em;
    @media screen and (max-width: 45em) {
      flex-direction: column;
      p {
        margin-top: 2px;
        text-align: center;
      }
    }
  }
  .content-author {
    &__images {
      display: flex;
      padding-right: 4px;
      & > * {
        margin: 0 -4px;
      }
      @media screen and (max-width: 45em) {
        margin: 1.2em 0 0 0;
      }
    }
  }
  small {
    color: ${theme.colors.gray["900"]};
    letter-spacing: 1px;
  }
  @media screen and (max-width: 45em) {
    margin: 1em 0;
    padding: 1em 1em;
    h3 {
      font-size: 22px;
      text-align: center;
    }
  }
`;

export default ArticleTile;
